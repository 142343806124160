*, :after, :before {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: inherit;
    word-break: break-word;
}

html {
    font-size: 62.5%;
}

body {
    font-size: 1.6rem;
    line-height: 1.5em;
    background-color: $color-light;
    color: $color-dark;
    font-family: $font-family-body;
}

html, body, #root {
    width: 100%;
    height: 100%;
}