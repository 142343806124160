.c-nav {
    &__list {
        display: flex;
        flex-direction: column;
        list-style-type: none;
        gap: 5px;
    }

    &__item {
        line-height: 1.5em;
    }

    &__link {
        text-transform: uppercase;
        font-size: 2rem;
        font-weight: 700;
        text-decoration: none;
        position: relative;

        &::before {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            height: 2px;
            width: 0;
            background-color: $color-dark;
            transition: width 300ms ease-in-out;
        }

        &::after {
            content: '\2192';
            position: absolute;
            left: 0;
            top: 50%;
            padding-right: 5px;
            transform: translate(-150%, -50%);
            opacity: 0;
            transition: 200ms ease-in-out;
        }

        &:hover,
        &:focus {
            &::before {
                width: 100%;
            }
        }

        &--active {
            &::after {
                transform: translate(-100%, -50%);
                opacity: 1;
            }
        }
    }
}