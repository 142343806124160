.o-alert {
    width: 100%;
    padding: 1rem 2rem;
    text-align: center;
    margin-bottom: 2rem;
    background-color: $color-information;
    color: $color-light;

    &.--success {
        background-color: $color-success;
    }

    &.--error {
        background-color: $color-fail;
    }
}