.m-home {
    padding: 50px 0;
    display: grid;
    flex-grow: 1;
    place-items: center;

    &__title {
        font-size: clamp(2.5rem,1.25rem + 6vw,5.65rem);
        color: #a90000;
        margin-bottom: 1rem;
        font-weight: 700;
        line-height: 1.2em;
    }

    &__introduction {
        font-size: 2.4rem;
        line-height: 1.5em;
    }
}