// Polices
$font-family-body: 'Source Sans Pro', sans-serif;
$font-family-serif: 'Pt Serif', serif;

// Couleurs
$color-light: #fff;
$color-dark: #252525;

$color-primary: $color-dark;

// Couleurs d'état
$color-information: #036DCF;
$color-success: #00840D;
$color-warning: #D74101;
$color-fail: #8B0101;
