.u-text-center {
    text-align: center;
}

.u-cover {
    &::before {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }
}